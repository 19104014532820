<template>
  <div class="video-call-interface">
    <section class="video-windows">
      <video ref="remoteVideo" class="local-video" controls="controls" ></video> <!--autoplay-->
      <video ref="localVideo"  class="remote-video" controls="controls" ></video>
    </section>
    <aside class="controls" v-if="isActive" >
      <div>
        <button v-if="!isSendVideo"  :style="{ backgroundColor: buttonColor }" @click="sendVideo">{{ isHangUp ? '' : '发起视频' }}</button>
        <button v-if="isSendVideo"   :style="{ backgroundColor: buttonColor }" @click="hangUp">{{ isHangUp ? '挂断' : '接听' }}</button>
        <button @click="toggleMicrophone">{{ isMicrophoneMuted ? '取消静音' : '静音' }}</button>
        <button @click="toggleCamera">{{ isCameraDisabled ? '开启摄像头' : '关闭摄像头' }}</button>
      </div>
    </aside>
    <!-- 如果有聊天窗口，可以在这里添加 -->
  </div>
</template>

<script>
import {useAuthStore} from "@/stores";

export default {
  name: "VideoCall",
  data() {
    return {
      isActive:false,
      isSendVideo:false,
      buttonColor: "#0ceb3c",
      isHangUp:false,
      isMicrophoneMuted: false,
      isCameraDisabled: false,
      // 这里可以添加其他状态，如WebRTC连接状态等
      socket: null,
      wsBaseURL: process.env.VUE_APP_API_WS_BASE_URL, // 对于Vue CLI项目
      client:null,
      heartbeatInterval: null,
      createOfferInterval: null,
      localto: this.to,
    };
  },
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  watch:{
    to:{
      handler(newVal, oldVal) {
        console.log(`RTCProp myProp changed from ${oldVal} to ${newVal}`);
        //this.localto=this.to; // 初始化本地数据为 props 的值
        // 在这里执行你想要的逻辑
        //handler() {
        this.isActive = false;
        if (this.socket) {
          this.socket.close();
        }
        if (this.client) {
          this.client.close();
        }
        // 停止心跳定时器和心跳超时定时器
        if (this.heartbeatInterval) {
          clearInterval(this.heartbeatInterval);
          this.heartbeatInterval = null;
        }
        if (this.createOfferInterval) {
          clearInterval(this.createOfferInterval);
          this.createOfferInterval = null;
        }
        if(this.oldVal) {
          this.connectWebSocket();
        }

      },
      immediate: false // 立即执行一次监听器
    },
  },
  mounted() {
    // 渲染完成后执行的方法
    this.connectWebSocket();
  },
  methods: {
    sendVideo() {

        this.isSendVideo = !this.isSendVideo;
        this.isHangUp = !this.isHangUp;
        this.createOffer();
        // 设置网络波动时可以重新连接视频
        this.createOfferInterval = setInterval(() => {
          this.createOffer();
        }, 5000); // 30秒超时时间
    },
    hangUp() {
      if(this.isHangUp){
        this.isSendVideo = !this.isSendVideo;
        // 实现挂断逻辑，如关闭WebRTC连接等
        console.log('挂断通话');
        if (this.socket) {
          this.socket.close();
        }
        if (this.client) {
          this.client.close();
        }

      }
      //实现接听/挂断
      this.isHangUp = !this.isHangUp;
      this.buttonColor = !this.isHangUp?'#0ceb3c':'#eb0c29';

    },
    toggleMicrophone() {
      // 实现麦克风静音/取消静音逻辑
      this.isMicrophoneMuted = !this.isMicrophoneMuted;
      // const localVideo = this.$refs.localVideo;
      // localVideo.muted = this.isMicrophoneMuted;
      // 这里还需要处理远程用户的麦克风静音状态，可能需要通过WebRTC信令来同步
    },
    toggleCamera() {
      // 实现摄像头开启/关闭逻辑
      this.isCameraDisabled = !this.isCameraDisabled;
      // const localVideo = this.$refs.localVideo;
      // console.log(localVideo.muted);
      // 这里需要处理摄像头的实际开启/关闭，可能需要使用MediaDevices.getUserMedia()等方法
      // 注意：由于隐私和安全原因，浏览器可能不会允许在运行时动态开启/关闭摄像头
      // 因此，通常的做法是在组件挂载时请求摄像头权限，并在需要时显示/隐藏视频流
    },

    connectWebSocket() {
      const store = useAuthStore();

      let serverConfig = {
        "iceServers": [
          //{ "urls": "stun:xiexeini.lol:3478" },
          { "urls": ["turn:xiexeini.lol:3478"],
            "username": ""+store.userInfo.name,
            "credential": ""+store.userInfo.rtc_pwd
          }
        ]
      };

      this.client = new RTCPeerConnection(serverConfig);

      const params = "name="+store.userInfo.name+"&token="+store.userToken;
      this.socket = new WebSocket(this.wsBaseURL+'sendMeg?'+params);
      var client = this.client;
      var socket=this.socket;
      var to=this.to;

      var localVideo = this.$refs.localVideo;
      var remoteVideo = this.$refs.remoteVideo;


      this.client.ontrack=function(event){
        console.log(event.track.kind+':ontrack:'+remoteVideo);
        //remoteVideo.srcObject=event.streams[0];
        //remoteVideo.play();

        if (event.track.kind === 'audio') {
          // 这是一个音频轨道
          // 你可能想要更新UI来反映音频正在播放
          // 例如，显示一个播放图标或更新状态文本
          // document.getElementById('audioStatus').textContent = 'Audio is playing';

          // 在这个例子中，我们不需要显式地将音频轨道附加到任何DOM元素上
          // 浏览器会自动处理音频的播放

          // 但是，如果你想要对音频进行进一步处理（如调整音量），
          // 你可能需要使用Web Audio API等更高级的技术
        } else if (event.track.kind === 'video') {
          // 处理视频轨道的代码（如上述示例）
          remoteVideo.srcObject=event.streams[0];
          remoteVideo.play();
        }

        // 注意：在这个简单的例子中，我们没有处理资源清理。
        // 在实际应用中，你应该确保在适当的时候停止和释放轨道资源。

      };


      this.client.onicecandidate=function (event) {
        var iceCandidate = event.candidate;
        var userInfo=  { type: 'webrtc', from: store.userInfo.name ,to: to,condidate: iceCandidate,signature: 'condidate'};
        //var userInfo={"uid":"1","state":"1","condidate":iceCandidate};
        // console.info(store.userInfo.name+' 发送 iceCandidate'+ JSON.stringify(userInfo));
        if(iceCandidate!=null)
        {
          //console.info("  用户1 发送的condidate"+iceCandidate)
          socket.send(JSON.stringify(userInfo));
          //client.addIceCandidate(new RTCIceCandidate(iceCandidate));
          //w3review.innerHTML = w3review.innerHTML + JSON.stringify(userInfo)+' 1发送ICE \r\n';
        }
      };









      this.socket.onopen = () => {
        this.heartbeat(store.userInfo.name);
        // 设置心跳超时定时器，等待pong响应
        this.heartbeatInterval = setInterval(() => {
          this.heartbeat(store.userInfo.name);
        }, 30000); // 30秒超时时间
        this.isActive = true;


        navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        }).then((stream) => {
          localVideo.srcObject=stream;
          stream.getTracks().forEach(function (track) {
            client.addTrack(track,stream);
            //w3review.innerHTML = w3review.innerHTML + 'onopen ->client.addTrack 完成\r\n';
          })


        });

      };

      this.socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log("client.connectionState :"+ client.connectionState);
        //console.log('Message'+message);
        //console.log(message);
        if (message.type === 'webrtc' && message.signature === 'ready') {
          console.log('RTCWebSocket connected ready');

        }else if(message.type === 'webrtc' && message.signature === 'answer'){
          //message.content = store.decryptMessage(message.content,this.privateKeyStr)
          if("connecting"!=client.connectionState && "connected"!=client.connectionState){
            client.setRemoteDescription(message.answer)
          }

        }else if(message.type === 'webrtc' && message.signature === 'offer'){
          //message.content = store.decryptMessage(message.content,this.privateKeyStr)
          if("connecting"!=client.connectionState && "connected"!=client.connectionState){
            client.setRemoteDescription(message.offer);
            client.createAnswer().then(desc=>{
              client.setLocalDescription(desc);
              //var userInfo={"desc":desc,"uid":"2","state":"3"};
              var userInfo=  { type: 'webrtc', from: store.userInfo.name ,to: to,answer: desc,signature: 'answer'};
              socket.send(JSON.stringify(userInfo));
            });
            this.isSendVideo = true;
          }

        }else if(message.type === 'webrtc' && message.signature === 'condidate'){
          //message.content = store.decryptMessage(message.content,this.privateKeyStr)
          // console.log(JSON.stringify(message.condidate))
          if(message.content!=null)
          {
            client.addIceCandidate(new RTCIceCandidate(message.condidate))
          }
        }

      };



      this.socket.onclose = () => {
        console.log('RTCWebSocket disconnected');
      };

      this.socket.onerror = (error) => {
        console.error('RTCWebSocket error:', error);
      };
    },
    // 其他方法，如建立WebRTC连接、处理信令等 setInterval setTimeout
    heartbeat(name){
      console.log('RTCWebSocket connected');
      this.socket.send(JSON.stringify({ type: 'webrtc', from: name ,to: this.to, signature: 'ready'}));

    },
    createOffer(){

      const store = useAuthStore();
      var socket=this.socket;
      var client = this.client;
      console.log("client.connectionState :"+ client.connectionState);
      if("connecting"!=client.connectionState && "connected"!=client.connectionState){
        this.client.createOffer().then((desc)=>{
          client.setLocalDescription(desc)
          //send server
          //var userInfo={"desc":desc,"uid":"1","state":"2"};
          var userInfo=  { type: 'webrtc', from: store.userInfo.name ,to: this.to,offer: desc,signature: 'offer'};
          socket.send(JSON.stringify(userInfo))
        });
      }

    },

  // }, 10000); // 10秒发送一次ping消息
  //   },



  },
  // 可以在这里添加生命周期钩子，如mounted()来初始化WebRTC连接等
  beforeUnmount() {
    // 停止心跳定时器和心跳超时定时器
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
    // 清除重新连接定时器（如果有）
    if (this.createOfferInterval) {
      clearInterval(this.createOfferInterval);
      this.createOfferInterval = null;
    }
  },

};
</script>

<style scoped>
.video-call-interface {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /*width: 100vw;*/
  background-color: #f0f0f0;
}
.video-windows {
  /*position: absolute;*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.local-video {
  /*position: absolute;*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 确保视频填充整个容器，同时保持其宽高比 */
}

.remote-video {
  position: absolute;
  top: 50px; /* 根据需要调整 */
  right: 10px; /* 根据需要调整 */
  width: 200px; /* 根据需要调整 */
  height: 150px; /* 根据需要调整 */
  object-fit: cover; /* 同样确保视频填充其容器，同时保持宽高比 */
  z-index: 1; /* 确保远程视频在本地视频之上（如果需要的话） */
}
/* 媒体查询：当屏幕宽度小于600px时（可调整阈值以适应不同手机） */
@media (max-width: 600px) {
  /*.remote-video {*/
  /*  left: 10px; !* 在手机上将宽度设置为0 *!*/
  /*  overflow: hidden; !* 防止内容溢出 *!*/
  /*}*/
}
/* 其他样式 */
/*.video-windows {*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  width: 80%;*/
/*  margin: 20px 0;*/
/*}*/

video {
  width: 45%;
  height: auto;
  background-color: #000;
}

.controls {
  display: flex;
  position: absolute;
  bottom: 0px;
  /*flex-direction: column;*/
  justify-content: center; /* 水平居中 */
  align-items: center;
  width: 80%;
  gap: 10px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
</style>
