// src/plugins/axios.js
import axios from 'axios';
import {useAuthStore} from "@/stores";
import router from '@/router';



const instance = axios.create({
    // baseURL: 'https://api.example.com', // 替换为你的 API 基础 URL
    // baseURL: import.meta.env.VITE_API_BASE_URL, // 对于Vite项目
    baseURL: process.env.VUE_APP_API_BASE_URL, // 对于Vue CLI项目
    timeout: 100000,
    headers: {
        'X-Custom-Header': 'foobar',
        // 其他头部可以添加
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Expose-Headers': 'Authorization',
        'Access-Control-Max-Age': '86400',

    },
});
// 将 Vue Router 实例添加到 Axios 实例上
instance.router = router;

// 添加请求拦截器
instance.interceptors.request.use(
    config => {
        const store = useAuthStore();
        // 检查请求的 URL 是否为登录或注册
        const isLoginOrRegister = config.url.startsWith('/authenticate') ||
            config.url.startsWith('/api/users/register') ||
            config.url.startsWith('/api/users/login');
        // 在这里可以添加 token 或其他请求头
        // const token = localStorage.getItem('token');
        const token = store.getToken();  //

        //console.log("请求头新增token"+token);
        if (!isLoginOrRegister&&token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
// 添加响应拦截器
// instance.interceptors.response.use(
//     response => {
//         return response;
//     },
//     error => {
//         // 对响应错误做点什么，比如显示错误消息
//         console.error('Error:', error);
//         return Promise.reject(error);
//     }
// );
// 添加响应拦截器
instance.interceptors.response.use(
    response => {
        // 对响应数据做点什么
        return response;
    },
    async error => {
        const {response} = error;
        // let errorMessage ={};
        // errorMessage.response = response.response;
        // errorMessage.status = response.status;
        // errorMessage.message = response.data.message;

        if (response) {
            // 根据状态码判断错误类型
            switch (response.status) {
                case 403:
                    // 处理 403 Forbidden 错误
                    alert('Access Denied. You do not have permission to access this resource.');
                    // console.log('Access Denied. You do not have permission to access this');
                    // console.log(error);
                    // 你可以重定向到登录页面或其他错误处理页面
                    // router.push('/login');
                    // await this.$router.push({name: 'Login'});
                    // 使用 instance.router 而不是 this.$router
                    await router.push({ name: 'Login' });
                    break;
                case 500:
                    // 处理 500 Internal Server Error 错误
                    alert('Internal Server Error. Please try again later.');
                    // 你可以显示一个错误消息或重定向到一个错误页面
                    break;
                // 你可以添加更多的状态码处理逻辑
                default:
                    // 处理其他错误
                    alert(`An error occurred: ${response.statusText}`);
                    // return Promise.reject(error);
            }
        } else if (error.request) {
            // 请求已发出，但没有收到响应
            console.error('No response received:', error.message);
        } else {
            // 发生在设置请求时
            console.error('Error in setting up request:', error.message);
        }
        // 返回 Promise.reject 以确保调用者可以捕获到这个错误
        // return Promise.reject(error);
        // return Promise.resolve(errorMessage);
        return Promise.resolve(error);
    }
);


export default instance;
