// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import {useAuthStore} from "@/stores";

import Home from '../views/Home.vue';
// import Login from '../views/LoginComponent.vue';
import Register from '../views/UserRegister.vue';
import AES_CBC from '../views/AES_CBC.vue';
// import LoadMegess from '../views/LoadMegess.vue';
// import SendMegess from '../views/SendMegess.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            isLogin: true,//需要登录权限
        },
    },
    // 添加更多的路由
    {
        path: '/login',
        name: 'Login',
        component: Register,
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
    },
    {
        path: '/aes_cbc',
        name: 'AES_CBC',
        component: AES_CBC,
    },
    // {
    //     path: '/loadMegess',
    //     name: 'LoadMegess',
    //     component: LoadMegess,
    // },
    // {
    //     path: '/sendMegess',
    //     name: 'SendMegess',
    //     component: SendMegess,
    // },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    // next();
    // if (to.meta.isAdmin) {
    //     if (useAuthStore().userData.role === "root") {
    //         next();
    //     } else {
    //         next({ name: "Index" });
    //     }
    // } else
   if (to.meta.isLogin) {

       //console.log("useAuthStore:"+useAuthStore());
       //console.log("useAuthStore:"+useAuthStore().isLoggedIn);
        if (useAuthStore().isLoggedIn) {
            next();
            //console.log(useAuthStore().isLoggedIn);
        } else {
            next({ name: "Login" });
        }
    } else {
        next();
    }
});
export default router;
