<template>
  <section class="weixin-friend-section">
    <h2 class="button1"  @click="handleClick">
      <span>新的朋友</span>
    </h2>

    <transition name="fade">

<!--      <p v-if="show">Hello, Vue!</p>-->
      <div v-if="show" class="container">
        <h1>添加用户名</h1>
        <!--        <label class="username">用户名:</label>-->
        <input type="text" id="username" name="addusername" v-model="addusername">
        <button  type="button"  @click="addFriend">添加好友</button>
        <ul>
          <div v-for="(pfriend, index) in pendingFriendlist" :key="index">
            <li class="weixin-pendingfriend-item">
              <div class="weixin-pendingfriend-info">
                <span class="weixin-nickname">{{pfriend.name}}</span>
                <!-- 其他信息，如添加按钮或备注等，可以放在这里 -->
                <div class="pendingfriend-button">
                  <button  type="button"  @click="confirmFriend(pfriend.name)">同意</button>
                  <button  v-if="false" type="button"  @click="refuseFriend(pfriend.name)">拒绝</button>
                </div>

              </div>
            </li>
          </div>
        </ul>
      </div>
    </transition>
    <ul>
      <div v-for="(friend, index) in friendList" :key="index">
        <li class="weixin-friend-item" @click="titleNmae(friend.name)">
<!--          <img src="avatar1.jpg" alt="好友头像" class="weixin-avatar">-->
          <div class="weixin-avatar"><SvgCreate :name="friend.name" :bColor="'#1575d5'"></SvgCreate></div>
          <div class="weixin-friend-info">
            <span class="weixin-nickname">{{friend.name}}</span>
            <span class="weixin-source">来源：手机通讯录</span>
            <!-- 其他信息，如添加按钮或备注等，可以放在这里 -->
          </div>
        </li>
      </div>
<!--      <li class="weixin-friend-item" @click="titleNmae">
        <img src="avatar1.jpg" alt="好友头像" class="weixin-avatar">
        <div class="weixin-friend-info">
          <span class="weixin-nickname">好友昵称</span>
          <span class="weixin-source">来源：手机通讯录</span>
          &lt;!&ndash; 其他信息，如添加按钮或备注等，可以放在这里 &ndash;&gt;
        </div>
      </li>-->

      <!-- 添加更多好友项 -->
    </ul>
  </section>
  <!-- 可以添加更多好友分组或聊天列表等 -->
  <Modal v-model:isVisible="showModal">
    <template #header>
      <h3></h3>
    </template>
    <p>{{showModalMessage}}</p>
    <template #footer>
      <!--        <button @click="confirmAction">Confirm</button>-->
      <button @click="showModal = false">关闭</button>
    </template>
  </Modal>
</template>

<script>
import {useAuthStore} from "@/stores";
import SvgCreate from "@/views/SvgCreate";
import Modal from "@/views/Modal";

export default {
  name: "LeftSidebar",
  emits: ['updateTitleName'],
  components: {
    SvgCreate,
    Modal
  },
  data() {
    return {
      show:false,
      friendList: [],
      pendingFriendlist: [],
      showModalMessage:'',
      showModal:false,
      addusername:null,

    }

  },
  created() {
    this.getFriendList();
    this.getPendingFriendlist();
  },
  methods:{
    titleNmae(title) {
      // // 使用原生 JavaScript 方法获取子元素（不推荐在 Vue 中这样做，除非有必要）
      // const parentElement = event.currentTarget; // 或者使用 this.$el 来获取组件的根元素
      // const childElement = parentElement.querySelector('.weixin-nickname');
      // console.log(childElement.value); // 输出子元素的 DOM 节点
      //
      // const titleNmae = 'Hello from child';
      //console.log(title);
      this.$emit('updateTitleName', title);
    },

    async getFriendList(){
      const store = useAuthStore();
      try {
        const friendList = await store.getFriendList({"name": store.userInfo.name});
        //console.log(friendList);
        this.friendList = friendList;
      } catch (error){
        console.error(error);
        this.showModalMessage = "好友查询失败";
        this.showModal  = true;
      }
    },
    async getPendingFriendlist(){
      const store = useAuthStore();
      try {
        const pendingFriendlist = await store.getPendingFriendlist({"name": store.userInfo.name});
        //console.log(friendList);
        this.pendingFriendlist = pendingFriendlist;
      } catch (error){
        console.error(error);
        this.showModalMessage = "待确认的好友查询失败";
        this.showModal  = true;
      }
    },
    async confirmFriend(name){
      const store = useAuthStore();
      try {
        await store.confirmFriend({"name": name});
        //console.log(friendList);
        // this.pendingFriendlist = confirmFriend;
        this.showModalMessage = "已同意好友请求";
        await this.getFriendList();
        await this.getPendingFriendlist();
      } catch (error){
        console.error(error);
        this.showModalMessage = "同意添加好友失败";
        this.showModal  = true;
      }

    },

    async addFriend(){
      const store = useAuthStore();
      try {
        await store.insertFriend({"name": this.addusername});
        //console.log(friendList);
        // this.pendingFriendlist = confirmFriend;
        this.showModalMessage = "已发送好友请求";
        await this.getFriendList();
        await this.getPendingFriendlist();
      } catch (error){
        console.error(error);
        this.showModalMessage = "同意发送好友失败";
      }
      this.showModal  = true;
      this.addusername = null;
    },

    handleClick() {
      // alert('按钮被点击了！');
      // 你可以在这里添加更多逻辑
      this.show =!this.show;
    }

  }
}
</script>

<style scoped>
.button1 {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s;
}

.button1:hover {
  background-color: #367a39;
}
/* 待确认好友分组标题样式 */
.weixin-pendingfriend-info{
  background-color: #f7f7f7;
  color: #333;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between; /* 子元素之间的间距 */
  align-items: center; /* 垂直方向居中对齐 */
}
.weixin-pendingfriend-info button {
  padding: 5px;
  border: 1px solid #000;
}
/* 好友分组标题样式 */
.weixin-friend-section h2 {
  font-size: 16px;
  margin-top: 0;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

/* 好友项样式 */
.weixin-friend-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.weixin-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.weixin-friend-info {
  /*flex-grow: 1;*/
  display: flex;/* 这里使用flex是为了方便后续可能添加更多子元素时的布局调整 */
  flex-direction: column; /* 将子元素垂直排列 */
  justify-content: space-between; /* 如果需要，可以在子元素之间添加额外的空间 */
}

.weixin-nickname {
  font-size: 14px;
  font-weight: bold;
}

.weixin-source {
  font-size: 12px;
  color: #999;
}
.weixin-nickname,
.weixin-source {
  text-align: left; /* 实际上，对于块级文本元素，这通常是默认行为，所以可能不是必需的 */
}

/* 其他可能的样式，如悬停效果、活跃状态等 */
.weixin-friend-item:hover {
  background-color: #f9f9f9;
}



</style>
