// stores/authStore.ts
import { defineStore } from 'pinia';
// import axios from 'axios'; // 假设你使用 axios 作为 HTTP 客户端
import instance from "@/plugins/axios";
import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';

const SECRET_KEY = 'your-secret-key'; // 用于生成签名的密钥，请确保其安全性

// 更新store的状态
const state1 = {
    userToken: '', //
    userInfo: {
        name: '',
        rtc_pwd: '',
        publicKeyStr:'',
    }, //
    signature:'',
    isLoggedIn: false,
    timestamp: new Date().getTime(), // 记录当前时间戳
    secretKey:'', //
    iv: CryptoJS.enc.Hex.parse('6c86945fcb5f6cf09f600852406b4c4e'), // 16字节的IV，对应AES的块大小
    privateKey: '', //

};
export const useAuthStore = defineStore('userStore', {
    state: () => ({
        isAuthenticated: false,
        userToken: '',
        userInfo:{
            name: '',
            rtc_pwd: '',
            publicKeyStr:'',
        },
        signature: '', // 存储签名
        timestamp: null, // 存储时间戳
        isLoggedIn: false,
        secretKey: '', //aes-secret-key
        //iv: CryptoJS.enc.Hex.parse('6c86945fcb5f6cf09f600852406b4c4e'), // 16字节的IV，对应AES的块大小
        iv: CryptoJS.enc.Hex.parse('6c86945fcb5f6cf09f600852406b4c4e'),
        privateKey: '', //
    }),
    computed: {
        ivstr() {
            // 替换换行符为 <br> 标签
            // 注意：这里假设 userInput 是安全的。如果它可能包含来自外部的数据，你需要先进行消毒处理。
            return CryptoJS.enc.Hex.parse('6c86945fcb5f6cf09f600852406b4c4e');
        }
    },
    watch: {
        // 监听 count 和 name 的变化

    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'userStore', // 自定义存储id，默认为当前状态id，即store.$id
                storage: localStorage, // 使用 localStorage 进行持久化 sessionStorage
                // paths: ['userToken', 'userInfo'], // 指定需要持久化的状态路径


            },
        ],
        // 自定义恢复前的钩子函数，用于验证签名
        beforeHydrate(storedState) {
            // 在从storage中恢复数据之前执行的操作
            const millisecondsInDay = 24 * 60 * 60 * 1000;
            const currentTime = new Date().getTime();

            // 在这里，mutation.payload 是即将恢复的状态
            const storedUserInfo = localStorage.getItem(storedState.store.$id); // 读取userInfo
            let state = {

            }
            if (storedUserInfo) {
                state = JSON.parse(storedUserInfo);
            }
            //console.log(state);
            const signature = state.signature;
            const timestamp = state.timestamp;

            //console.log("storedState-signature:" +signature);
            //console.log("storedState-timestamp:" +timestamp);


            //console.log(JSON.stringify(state));
            //console.log("millisecondsInDay:" + millisecondsInDay);
            //console.log(((currentTime-timestamp)<millisecondsInDay));
            if (!signature||!timestamp||((currentTime-timestamp)>millisecondsInDay)) {
                //console.log("没有签名或过期不恢复"+state1);
                localStorage.setItem(storedState.store.$id,JSON.stringify(state1)); // 读取userInfo
                //console.log(state1);
                storedState.store.$state=state1;
                //console.log(storedState.store.$state);
                return storedState;
            }
            const dataString = JSON.stringify(state).replace(/"signature":".+?"/g, '"signature":""'); // 移除签名后再序列化数据
            const generatedSignature = CryptoJS.HmacSHA256(dataString, SECRET_KEY).toString(CryptoJS.enc.Hex);
            //console.log("storedState-dataString:" +dataString);
            //console.log("signature:" +signature);
            //console.log("generatedSignature:" +generatedSignature);
            if (generatedSignature !== signature) {
                throw new Error('Data signature verification failed!'); // 签名不匹配，抛出错误
            }

            // 如果需要，可以在这里返回修改后的state，或者直接返回storedState（已验证）
            return storedState;
        },
        // afterHydrate: (context) => {
            // 在从storage中恢复数据之后执行的操作
            //console.log(`Just restored '${context.store.$id}'`);
            // 可以在此处添加其他逻辑，如数据更新或通知等
        // },
        debug: true, // 当设置为true时，会输出持久化/恢复Store时可能发生的任何错误
    },
    getters: {
        // 通常认证 store 不需要太多 getters，因为状态比较简单
        // 但可以根据需要添加
    },
    actions: {
        /**
         * 处理用户登录的动作。
         * @param {Object} credentials - 登录所需的凭证对象。
         * @param {string} credentials.name - 用户名。
         * @param {string} credentials.hsmac - 密码。
         */ async login(credentials) {
            const response = await instance.post('/api/users/login', credentials);
            // 假设后端返回的数据结构为 { token, user }
            // console.log(response); // true
            if(response.status ===200&& response.data.status_code ==="200"){
                // console.log("登录成功"); // true
                // 存储token
                // localStorage.setItem('token', response.headers.get("token"));
                this.userToken = response.headers.get("token");
                this.userInfo = response.data.data;
                this.isLoggedIn = true;
                this.isAuthenticated = true;
                this.timestamp=new Date().getTime();
                //console.log('路由跳转失败:');
                //console.log(this.$state);
                //console.log(this);
                this.signature="XXXXXXXXXXXXXXXX";//初始化
                const dataString = JSON.stringify(this.$state).replace(/"signature":".+?"/g, '"signature":""'); // 移除签名后再序列化数据
                this.signature = CryptoJS.HmacSHA256(dataString, SECRET_KEY).toString(CryptoJS.enc.Hex);

            }
            // console.log("登录成功1"); // true
            // 其他登录逻辑...
            //console.log(credentials); // true
        },
        /**
         * 处理用户注册的动作。
         * @param {Object} credentials - 登录所需的凭证对象。
         * @param {string} credentials.name - 用户名。
         * @param {string} credentials.hsmac - 密码。
         * @param {string} credentials.spare1 - email。
         */
        async register(credentials){
            const response = await instance.post('/api/users/register', credentials);
            if(response.status ===200&& response.data.status_code ==="200"){
                console.log('注册用户成功');
            }else {
                throw new Error('注册失败');
            }
        },
        /**
         * 处理用户修改密码的动作。
         * @param {Object} credentials - 登录所需的凭证对象。
         * @param {string} credentials.name - 用户名。
         * @param {string} credentials.hsmac - 密码。
         * @param {string} credentials.spare1 - 原密码。
         */
        async resetPwd(credentials){
            const response = await instance.post('/api/users/resetPwd', credentials);
            if(response.status ===200&& response.data.status_code ==="200"){
                console.log('密码修改成功');
            }else {
                throw new Error('密码修改失败');
            }
        },
        /**
         * 处理用户查询好友列表的动作。
         * @param {Object} credentials - 登录所需的凭证对象。
         * @param {string} credentials.name - 用户名。
         */
        async getFriendList(credentials){
            const response = await instance.post('/api/friend/getFriendList', credentials);
            if(response.status&&response.status ===200&& response.data.status_code ==="200"){
                console.log('查询好友列表成功');
                return response.data.data;
            }else {
                throw new Error('查询好友列表失败');
            }
        },
        /**
         * 查询待确认的好友请求。
         * @param {Object} credentials - 登录所需的凭证对象。
         * @param {string} credentials.name - 用户名。
         */
        async getPendingFriendlist(credentials){
            const response = await instance.post('/api/friend/getPendingFriendlist', credentials);
            if(response.status&&response.status ===200&& response.data.status_code ==="200"){
                console.log('查询待确认的好友列表成功');
                return response.data.data;
            }else {
                throw new Error('查询待确认的好友列表失败');
            }
        },
        /**
         * 查询待确认的好友请求。
         * @param {Object} credentials - 登录所需的凭证对象。
         * @param {string} credentials.name - 用户名。
         */
        async confirmFriend(credentials){
            const response = await instance.post('/api/friend/confirmFriend', credentials);
            if(response.status&&response.status ===200&& response.data.status_code ==="200"){
                console.log('确认好友请求成功');
                return response.data.data;
            }else {
                throw new Error('确认好友请求失败');
            }
        },
        /**
         * 查询待确认的好友请求。
         * @param {Object} credentials - 登录所需的凭证对象。
         * @param {string} credentials.name - 用户名。
         */
        async insertFriend(credentials){
            const response = await instance.post('/api/friend/insertFriend', credentials);
            if(response.status&&response.status ===200&& response.data.status_code ==="200"){
                console.log('添加好友请求成功');
                return response.data.data;
            }else {
                throw new Error('添加好友请求失败');
            }
        },

        logout() {
            this.isAuthenticated = false;
            this.isLoggedIn = false;
            this.userToken = '';
            this.userInfo={};
            // 其他登出逻辑...
            this.$data=state1;

        },
        getToken() {
            return this.userToken;
        },
        // 其他 actions...
        encryptMessage(message) {
            const base64PublicKey = this.userInfo.publicKeyStr;
            // 将Base64编码的公钥转换为PEM格式
            const publicKeyPem = `-----BEGIN PUBLIC KEY-----\n${base64PublicKey.match(/.{1,64}/g)?.join('\n')}\n-----END PUBLIC KEY-----`;
            const crypt = new JSEncrypt();
            crypt.setPublicKey(publicKeyPem);
            const encryptedMessage = crypt.encrypt(message);
            //console.log(encryptedMessage);
            return encryptedMessage;
        },
        decryptMessage(encryptedMessage,privateKeyStr) {
            const base64PrivateKey = privateKeyStr; // 假设这是用户的私钥，以Base64编码
            // 将Base64编码的私钥转换为PEM格式
            const privateKeyPem = `-----BEGIN PRIVATE KEY-----\n${base64PrivateKey.match(/.{1,64}/g)?.join('\n')}\n-----END PRIVATE KEY-----`;
            const crypt = new JSEncrypt();
            crypt.setPrivateKey(privateKeyPem);
            const decryptedMessage = crypt.decrypt(encryptedMessage);
            //console.log(decryptedMessage);
            return decryptedMessage;
        },
        decryptAESMessage(ciphertext,key) {

            const bytes = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Hex.parse(key), {
                iv: this.iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            const decryptedtext = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedtext;
        },


        generateRandomKey() {
            // 生成一个随机的密钥（这里以16个字符的密钥为例，实际使用中应该根据需要选择合适的长度）
            //this.secretKey = CryptoJS.lib.WordArray.random(16/4).toString(CryptoJS.enc.Hex);
            const secretKey = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex);

            return  secretKey;
        },


        // encryptAESMessage(message, key) {
        //     // 使用AES算法和生成的密钥进行加密
        //     const ciphertext = CryptoJS.AES.encrypt(message, key).toString();
        //     return ciphertext;
        // },
        // decryptAESMessage(ciphertext, key) {
        //     // 使用AES算法和相同的密钥进行解密
        //     const bytes = CryptoJS.AES.decrypt(ciphertext, key);
        //     const originalText = bytes.toString(CryptoJS.enc.Utf8);
        //     return originalText;
        // },
        // generateKeyAndEncrypt() {
        //     // 生成随机密钥
        //     this.generateRandomKey();
        //
        //     // 加密消息
        //     this.encryptedMessage = this.encryptAESMessage(this.message, this.secretKey);
        //
        //     // 解密消息以验证加密是否成功
        //     this.decryptedMessage = this.decryptAESMessage(this.encryptedMessage, this.secretKey);
        // },


    },



});
