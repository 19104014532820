// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
// import pinia from './stores';
//import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import instance from './plugins/axios';
// import {createPinia} from "pinia/dist/pinia"; // 引入你配置的Axios实例
import { createPinia } from 'pinia';
//import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';// localStorage
// 引入持久化插件
//import piniaPersistedState from 'pinia-persistedstate'; // //自定义位置
import {useAuthStore} from "@/stores";

// 引入 Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCamera,faEye, faEyeSlash,faPhone, faMobile,faFilm  } from '@fortawesome/free-solid-svg-icons';

// 添加图标到库
library.add(faCamera,faEye, faEyeSlash,faPhone, faMobile,faFilm  );



// 定义特性标志
window.__VUE_PROD_DEVTOOLS__ = false;
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;


const pinia = createPinia();

//pinia.use(piniaPersistedState);
//pinia.use(piniaPersistedState); // 正确使用插件
// 引入持久化插件
import piniaPluginpersistedstate from 'pinia-plugin-persist'
pinia.use(piniaPluginpersistedstate)
const app = createApp(App);
app.use(router);
app.use(pinia);

// 将从 store 中获取 token 的函数添加到全局属性
app.config.globalProperties.$getPiniaToken = function () {
    const authStore = useAuthStore();
    authStore.init();
    return authStore.getToken();
};

// 注册全局组件
app.component('font-awesome-icon', FontAwesomeIcon);

//app.use(Antd);

// 使用provide提供Axios实例
// app.provide('axios', axiosInstance);
app.config.globalProperties.$axios = instance;
app.mount('#app');
