import { defineStore } from 'pinia';

export const useMessageStore = defineStore('useMessageStore', {
    state: () => ({
        // 定义你的状态
        chatData: {}
    }),
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'useMessageStore',
                storage: sessionStorage,
                paths: ['chatData'] // 指定要持久化的状态路径
            }
        ]
    },
    actions: {
        setMessages(messages){
            this.chatData = messages;
        },
        getMessages(){
            return this.chatData;
        },
        logout() {
            this.chatData ={};
            // 其他登出逻辑...

        },
    }
});

